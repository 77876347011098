import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/SEO';
import HomeView from '../components/HomeView';

import '../styles/global.scss';

class IndexPage extends React.PureComponent {
  render() {
    return (
      <Layout view="home">
        <Seo title="Home" />
        <HomeView />
      </Layout>
    );
  }
}

export default IndexPage;
