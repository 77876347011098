import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import Text from '../Text';
import withMediaTypes from '../../types/withMediaTypes';

class TileView extends React.PureComponent {
  static propTypes = {
    ...withMediaTypes,
    showCTA: PropTypes.bool,
  };

  render() {
    return (
      <section className="cliq">
        <div className="tile">
          <div
            className="content row-reverse"
            data-rellax-speed="-2"
            data-rellax-percentage="0.5"
          >
            <div className="content-column text-container">
              <Text
                className="intro"
                tag="p"
                color="black"
                widows={3}
                value="Introducing Cliq"
              />
              <Text
                className="header"
                color="black"
                widows={3}
                value=" Our premium oil has met its match"
              />
              <Text
                className="desc"
                tag="p"
                color="black"
                widows={3}
                value="Cliq is the culmination of years of research and development to combine our renowned high-quality Elite oil with an intelligently designed vape delivery system. Welcome to what's next."
              />
              {!!this.props.showCTA && (
                <Button
                  id="eliteLiveButton"
                  color="black"
                  isTransparent
                  hasUnderline
                  hasBorder={false}
                  to="/cliq"
                >
                  Click for more
                </Button>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default TileView;
