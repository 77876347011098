import React from 'react';
import { Power2, TweenMax } from 'gsap/TweenMax';
import Text from '../Text';
import withMediaTypes from '../../types/withMediaTypes';

import LayoutContext from '../Layout/LayoutContext';

class Splash extends React.PureComponent {
  static propTypes = {
    ...withMediaTypes,
  };

  componentDidMount() {
    this.isLoggedIn = this.context.isLoggedIn;
    if (this.isLoggedIn) {
      setTimeout(this.animateIn, 1000);
    }
    setTimeout(
      () => {
        TweenMax.to(['#HomeHero--desc'], 0, {
          y: 10,
        });
      },
      this.isLoggedIn ? 100 : 1000,
    );
    window.addEventListener('resize', this.resize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.isLoggedIn !== this.context.isLoggedIn) {
      clearTimeout(this.animateTimeout);
      this.animateTimeout = setTimeout(this.animateIn, 1500);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  animateIn = () => {
    TweenMax.to('#HomeHero--desc', 0.7, {
      autoAlpha: 1,
      y: 0,
      ease: Power2.easeOut,
    });
  };

  resize = () => {
    this.forceUpdate();
  };

  static contextType = LayoutContext;

  render() {
    const { windowWidth } = this.props;

    return (
      <section className="splash">
        <div className="tile">
          <div
            className="content"
            data-rellax-speed="-2"
            data-rellax-percentage="0.5"
          >
            <div className="desktop content-column" />
            <div className="content-column">
              {windowWidth > 768 ? (
                <Text
                  className="header"
                  value="It all started with a better cartridge."
                />
              ) : (
                <>
                  <Text className="header" value="It all started" />
                  <Text className="header" value="with a better" />
                  <Text className="header" value="cartridge." />
                </>
              )}
              <Text
                className="desc"
                widows={3}
                value="
              The story of Select started with a cough, which led to a thought,
              which led to a better cartridge. Since day one our focus has been on
              product safety, and we’ve risen above because of our commitment to setting standards around pesticide,
              hardware and oil testing."
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Splash;
