import React from 'react';
import Button from '../Button';
import Text from '../Text';
import withMediaTypes from '../../types/withMediaTypes';

import productFamilySrc from '../../images/home/product-family.png';

class TileView extends React.PureComponent {
  static propTypes = {
    ...withMediaTypes,
  };

  render() {
    const { windowWidth } = this.props;

    return (
      <section className="product-family">
        <div className="tile">
          <div
            className="content"
            data-rellax-speed="-2"
            data-rellax-percentage="0.5"
          >
            <div className="content-column left">
              {windowWidth < 1024 ? (
                <>
                  <Text className="header" value="Explore Select" />
                  <Text className="header" value="products." />
                  <Text
                    className="desc"
                    value="Oil, gummies, tinctures and more. However you like your cannabis,
                    we have something for you."
                  />
                  <Button
                    id="eliteLiveButton"
                    hasBorder={false}
                    hasUnderline
                    color="white"
                    isTransparent
                    to="/products"
                  >
                    View Products
                  </Button>
                </>
              ) : (
                null
              )}
            </div>
            <div className="content-column right">
              {windowWidth > 1024 ? (
                <>
                  <Text className="header" value="Explore Select products." />
                  <Text
                    className="desc"
                    value="Oil, gummies, tinctures and more. However you like your cannabis,
                  we have something for you."
                  />
                  <Button
                    id="eliteLiveButton"
                    hasBorder={false}
                    hasUnderline
                    color="white"
                    isTransparent
                    to="/products"
                  >
                    View Products
                  </Button>
                </>
              ) : (
                null
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default TileView;
