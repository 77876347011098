import React from 'react';
import Button from '../Button';
import Text from '../Text';
import withMediaTypes from '../../types/withMediaTypes';

import MixItLockUp from '../../images/home/MixIt_Homepage_lockup.png';
import MixItIllustration from '../../images/home/MixIt_Homepage_illustration.png';
import MixItBottle from '../../images/home/MixIt_Homepage_bottle lockup.png';
import MixItWave from '../../images/home/MixIt_Homepage_wave.png';

class Squeeze extends React.PureComponent {
  static propTypes = {
    ...withMediaTypes,
  };

  render() {
    return (
      <section className="squeeze">
        <div className="tile">
          <div
            className="content row-reverse"
            data-rellax-speed="-2"
            data-rellax-percentage="0.5"
          >
            <div className="content-column content-wrapper">
              <div className="content-container">
                <img
                  className="mixit-lockup"
                  src={MixItLockUp}
                  alt="Select squeeze mix it header"
                />
                <Text
                  className="desc mixit-desc"
                  tag="p"
                  color="white"
                  widows={3}
                  value={'<span style="font-size: 50px; font-weight: 600; line-height: 1.25">Introducing Select Squeeze,</span><span> a perfect dose 5mg THC Beverage Enhancer. Available in 4 delicious flavors, effects in as little as 15 minutes. Take it anywhere. Mix it with anything. Feel it fast. Try it in your favorite beverage-or check out our recipes for inspiration.</span>'}
                />
                <img
                  className="mixit-illustration"
                  src={MixItIllustration}
                  alt="Select squeeze thc beverage enhancer"
                />
              </div>
              <div className="button-container">
                <Button
                  color="white"
                  isTransparent
                  hasUnderline
                  hasBorder={false}
                  to="/find-us"
                >
                  Find a Store
                </Button>
                <Button
                  color="white"
                  isTransparent
                  hasUnderline
                  hasBorder={false}
                  to="/mix-it-recipes"
                >
                  Try Recipes
                </Button>
              </div>
            </div>
            <div className="mixit-footer">
              <img
                className="mixit-bottle"
                src={MixItBottle}
                alt="Select squeeze banner bottom bottle"
              />
              <img
                className="mixit-wave"
                src={MixItWave}
                alt="Select squeeze banner bottom wave"
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Squeeze;
