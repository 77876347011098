import React from 'react';
// eslint-disable-next-line
import rellax from 'rellax';
import withMediaTypes from '../../types/withMediaTypes';
import Button from '../Button';
import Text from '../Text';

class HomeMap extends React.PureComponent {
  moodRef = React.createRef();

  static propTypes = {
    ...withMediaTypes,
  };

  resize = () => {
    if (this.rellax) this.rellax.refresh();
    this.forceUpdate();
  };

  render() {
    return (
      <section className="find-us">
        <div className="tile">
          <div
            className="content"
            data-rellax-speed="-2"
            data-rellax-percentage="0.5"
          >
            <div className="content-column">
              <Text
                className="header"
                color="white"
                value="Your day is about"
              />
              <Text className="header" color="white" value="to get better." />
              <Text
                className="desc"
                color="white"
                value="Get Select delivered or find your nearest dispensary."
              />
              <Button
                color="white"
                hasBorder={false}
                hasUnderline
                isTransparent
                to="/find-us"
              >
                Find Select
              </Button>
            </div>
            <div className="desktop content-column" />
          </div>
        </div>
      </section>
    );
  }
}

export default HomeMap;
