import React from 'react';
import Button from '../Button';
import Text from '../Text';
import withMediaTypes from '../../types/withMediaTypes';

class TileView extends React.PureComponent {
  static propTypes = {
    ...withMediaTypes,
  };

  render() {
    return (
      <section className="nano-gummies">
        <div className="tile">
          <div
            className="content row-reverse"
            data-rellax-speed="-2"
            data-rellax-percentage="0.5"
          >
            <div className="content-column text-container">
              <Text
                className="header-lg"
                color="black"
                widows={3}
                value="Bolder."
              />
              <Text
                className="header-lg"
                color="black"
                widows={3}
                value="Yummyer."
              />
              <Text
                className="header-lg"
                color="black"
                widows={3}
                value="Betterer."
              />
              <Text
                className="desc"
                tag="p"
                color="black"
                widows={3}
                value="New Select BITES. Better than everer.</span>"
              />
              <Button
                color="black"
                isTransparent
                hasUnderline
                hasBorder={false}
                to="/products"
              >
                See All Products
              </Button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default TileView;
