import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import Text from '../Text';
import withMediaTypes from '../../types/withMediaTypes';

import flowerSharperSrc from '../../images/home/flowerAlpha.png';
import cartridgeSrc from '../../images/home/cartridge.png';

class TileView extends React.PureComponent {
  static propTypes = {
    ...withMediaTypes,
    showCTA: PropTypes.bool,
  };

  render() {
    return (
      <section className="elite-live">
        <div className="tile">
          <div
            className="content row-reverse"
            data-rellax-speed="-2"
            data-rellax-percentage="0.5"
          >
            <div className="desktop content-column">
              <img className="cartridge" src={cartridgeSrc} alt="cartridges" />
            </div>
            <div className="content-column text-container">
              <Text
                className="header"
                color="black"
                widows={3}
                value=" The ease of oil, the feeling of"
              />
              <img
                className="flower-image"
                src={flowerSharperSrc}
                alt="sharper"
              />
              <Text
                className="desc"
                tag="p"
                color="black"
                widows={3}
                value="Select Elite Live Oil."
              />
              {!!this.props.showCTA && (
                <Button
                  id="eliteLiveButton"
                  color="black"
                  isTransparent
                  hasUnderline
                  hasBorder={false}
                  to="/products"
                >
                  See All Products
                </Button>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default TileView;
