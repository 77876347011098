import React from 'react';

import withMediaTypes from '../../types/withMediaTypes';
import withMedia from '../../hoc/withMedia';

import Products from './Products';
import EliteLive from './EliteLive';
import Splash from './Splash';
import Squeeze from './Squeeze';
import NanoGummies from './NanoGummies';
import FindUs from './FindUs';
import Cliq from './Cliq';

import './HomeView.scss';

class HomeView extends React.PureComponent {
  static propTypes = {
    ...withMediaTypes,
  };

  render() {
    return (
      <>
        <article className="home">
          <Cliq {...this.props} showCTA />
          <Squeeze {...this.props} />
          <Splash {...this.props} />
          <NanoGummies {...this.props} />
          <EliteLive {...this.props} showCTA />
          <Products {...this.props} />
          <FindUs {...this.props} />
        </article>
      </>
    );
  }
}

export default withMedia(HomeView);
